<template>
    <div
        ref="hero"
        class="home-hero section-wrapper"
        :class="viewport.is"
    >
        <section
            :style="{
                '--scale': viewport.is.mobile ? 1 : Math.min(0.75 + heroScroll, 1),
                '--translate': viewport.is.mobile ? 0 : Math.min(-64 + heroScroll * 800, 260) + 'px',
                '--padding': viewport.is.mobile ? 0 : Math.min(heroScroll * 800, 324) + 'px'
            }"
        >
            <GradientText :size="viewport.is.mobile ? 'xl' : 'xxl'" heading tag="h1">
                A complete course building platform
            </GradientText>
            <Txt :size="viewport.is.mobile ? 'xl' : 'xxl'">
                Create, share and analyze unlimited courses with unlimited viewers
            </Txt>
            <video
                v-if="viewport.is.mobile"
                :src="url('/assets/videos/hero-video-mobile.mp4')"
                :poster="url('/assets/videos/hero-poster.jpg')"
                width="2000"
                height="1000"
                controls
            />
            <Button
                variant="create"
                size="s" heading
                href="/build"
                data-placement="hero"
            >
                Create course
            </Button>
            <video
                v-if="!viewport.is.mobile"
                :src="url('/assets/videos/hero-video.mp4')"
                :poster="url('/assets/videos/hero-poster.jpg')"
                muted
                autoplay
                loop
                playsinline
                width="2000"
                height="1000"
            />
        </section>
    </div>
</template>

<script>
// eslint-disable-next-line
definePageMeta({
    layout: 'cms',
});

import { url } from '@/helpers/utilities';
import { useResponsive } from '@/store/responsive';

import Txt from '@/components/form/txt';
import Button from '@/components/utility/button';
import GradientText from '@/components/animation/gradient-text';

export default {
    name: 'HomePage', 
    components: { Txt, Button, GradientText },
    inject: ['$global'],
    data() {
        return {
            heroScroll: 0,
            viewport: { is: {} }
        };
    },
    mounted() {
        this.$track.baseProperties = {
            ...this.$track.baseProperties,
            'page_name': 'homepage',
            'page_type': 'marketing'
        };

        document.addEventListener('scroll', this.onScroll, true);
        this.viewport = useResponsive();
    },
    methods: {
        url,
        onScroll() {
            // Track progress of how far we've scrolled past the top of the hero
            const hero = this.$refs.hero;
            if (hero) {
                const { top, height } = hero.getBoundingClientRect();
                this.heroScroll = Math.min(1, Math.max(0, -top / height));
            }
        }
    }
};
</script>

<style lang="less" scoped>
.home-hero.section-wrapper {
    padding: 64px 24px 0;
    text-align: center;
    color: var(--navy);
    section {
        gap: 24px;
        padding-bottom: var(--padding);
        margin-bottom: -64px;
        .cobutton {
            padding: 16px 40px;
            margin: 16px 0;
        }
        video {
            max-width: 1200px;
            height: auto;
            width: 100%;
            border-radius: 24px;
            transform: scale(var(--scale)) translateY(var(--translate));
        }
    }
    &.mobile {
        padding: 64px 24px 24px;
        section {
            padding: 0;
            margin: 0;
        }
    }
}
</style>
